import { Flex, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

import Button from '@ui-kit/atoms/button/button';
import IconButton from '@ui-kit/atoms/icon-button/icon-button';
import IconHeartFill from '@ui-kit/icons/icon-heart-fill';
import IconHeartOutline from '@ui-kit/icons/icon-heart-outline';

type Props = {
  isLiked?: boolean;
  canBuy?: boolean;
  onLike: () => void;
  onBuy: () => void;
};

export const PlayerFooter = ({
  isLiked = false,
  canBuy,
  onLike,
  onBuy,
}: Props) => {
  const t = useTranslations();

  return (
    <Flex
      alignItems='center'
      gap={5}
      mt='auto'
    >
      <IconButton
        aria-label='like'
        onClick={onLike}
        color='white'
        size='md'
        colorScheme='transparent'
        variant='solid'
      >
        {isLiked ? <IconHeartFill /> : <IconHeartOutline />}
      </IconButton>
      {canBuy && (
        <Button
          onClick={onBuy}
          w='100%'
          colorScheme='green'
        >
          <Text textStyle='heading-large-sm'>{t('PlayerFooter.buy')}</Text>
        </Button>
      )}
    </Flex>
  );
};
