import { Button, ButtonProps, Stack } from '@chakra-ui/react';

import { useIsMobile } from '@/shared/ui-kit/hooks/useIsMobile';
import IconNext from '@/shared/ui-kit/icons/icon-next';
import IconPause from '@/shared/ui-kit/icons/icon-pause';
import IconPlay from '@/shared/ui-kit/icons/icon-play';
import IconPrev from '@/shared/ui-kit/icons/icon-prev';

type Props = {
  onPlay: () => void;
  onPause: () => void;
  isPlaying?: boolean;
  onNext: () => void;
  onPrevious: () => void;
  isLoading?: boolean;
  hasNext?: boolean;
  hasPrevious?: boolean;
};
export const PlayerControls = ({
  onNext,
  onPause,
  onPlay,
  onPrevious,
  isLoading,
  hasNext,
  hasPrevious,
  isPlaying,
}: Props) => {
  const isMobile = useIsMobile();
  const playPauseButtonsProps: ButtonProps = {
    isLoading,
    colorScheme: isMobile ? 'white' : 'black',
    size: { base: 'md' },
    borderRadius: 60,
    padding: 0,
  };
  return (
    <Stack
      direction='row'
      my={{ base: 7, md: 0 }}
      alignItems='center'
      gap={5}
      justifyContent='center'
    >
      <Button
        size='md'
        padding={0}
        bg='transparent'
        onClick={onPrevious}
        disabled={!hasPrevious}
        isDisabled={!hasPrevious}
        _disabled={{
          opacity: '0.4',
          cursor: 'not-allowed',
          _hover: {
            background: 'transparent',
          },
        }}
        color='states.text&icon.disabled'
      >
        <IconPrev />
      </Button>
      {isPlaying ? (
        <Button
          {...playPauseButtonsProps}
          onClick={onPause}
        >
          <IconPause />
        </Button>
      ) : (
        <Button
          {...playPauseButtonsProps}
          onClick={onPlay}
        >
          <IconPlay />
        </Button>
      )}
      <Button
        size='md'
        bg='transparent'
        _disabled={{
          cursor: 'not-allowed',
          opacity: '0.4',
          _hover: {
            background: 'transparent',
          },
        }}
        padding={0}
        onClick={onNext}
        disabled={!hasNext}
        isDisabled={!hasNext}
        color='states.text&icon.disabled'
      >
        <IconNext />
      </Button>
    </Stack>
  );
};
