import { useUnit } from 'effector-react';
import { useEffect } from 'react';

import { Snippet } from '@/features/audio-player/types';

import { usePlayer } from './use-player';
import {
  $currentTrack,
  $isNextTrack,
  $isPreviousTrack,
  $playerState,
  nextTrack,
  previousTrack,
  setCurrentTrackIndex,
  setPlayerState,
} from '../model';

export const usePlayerControls = () => {
  const { state } = usePlayer();

  const [
    onNextTrack,
    onPreviousTrack,
    currentTrack,
    playerState,
    onPlayerState,
    isNextTrack,
    isPreviousTrack,
  ] = useUnit([
    nextTrack,
    previousTrack,
    $currentTrack,
    $playerState,
    setPlayerState,
    $isNextTrack,
    $isPreviousTrack,
  ]);

  const audioElement = state.audioElement;

  useEffect(() => {
    if (
      audioElement &&
      currentTrack?.url &&
      audioElement.src !== currentTrack.url
    ) {
      audioElement.src = currentTrack.url;
    }
  }, [playerState, audioElement, currentTrack?.url]);

  useEffect(() => {
    if (playerState === 'playing') {
      if (!state.audioElement) {
        onPlayerState('pause');
        return;
      }

      if (state.audioElement.error && currentTrack?.url) {
        state.audioElement.src = currentTrack.url;
      }

      state.audioElement?.play();
    }

    if (playerState === 'pause') {
      if (!state.audioElement) {
        return;
      }

      state.audioElement?.pause();
    }
  }, [currentTrack?.url, onPlayerState, playerState, state.audioElement]);

  return {
    play: () => setPlayerState('playing'),
    pause: () => setPlayerState('pause'),
    playByIndex: (index: number) => {
      setCurrentTrackIndex(index);
      setPlayerState('playing');
    },
    isPlaying: playerState === 'playing',
    checkIsActiveTrack: (snippet: Snippet) => {
      return currentTrack === snippet && playerState === 'playing';
    },
    next: onNextTrack,
    previous: onPreviousTrack,
    isNextTrack,
    isPreviousTrack,
  };
};
