import { useRouter } from 'next/navigation';
import { signIn, useSession } from 'next-auth/react';
import { useMemo } from 'react';

import { usePlayerData } from '@/features/audio-player/hooks/use-player-data';
import { PlayerFooter } from '@/features/audio-player/ui/player-footer/player-footer';
import { useFavorites } from '@/features/favorites';

export const FooterConnector = () => {
  const { data } = usePlayerData();
  const campaignId = data?.currentCampaignId;
  const session = useSession();
  const { push } = useRouter();
  const canBuy = data?.canBuy;
  const { favorites, addToFavorite, removeFromFavorite } = useFavorites();

  const handleToggleFavorite = () => {
    if (!data?.currentCampaignId) {
      return;
    }

    if (!data?.isFavoriteCampaign) {
      addToFavorite.mutate(data.currentCampaignId);
      return;
    }
    removeFromFavorite.mutate(data.currentCampaignId);
  };

  const handleBuy = async () => {
    const callbackUrl = `/project/purchase/${campaignId}`;
    if (session.status === 'unauthenticated') {
      await signIn('keycloak', { callbackUrl });
      return;
    }
    if (campaignId === null) {
      return;
    }

    push(callbackUrl);
  };
  const isFavorite = useMemo(
    () => favorites.includes(data?.currentCampaignId || ''),
    [favorites, data],
  );
  return (
    <PlayerFooter
      isLiked={isFavorite}
      canBuy={canBuy}
      onLike={handleToggleFavorite}
      onBuy={handleBuy}
    />
  );
};
