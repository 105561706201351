import { MiniPlayerDesktop } from '@/features/audio-player/ui/mini-player/mini-player-desktop';
import { MiniPlayerMobile } from '@/features/audio-player/ui/mini-player/mini-player-mobile';
import { MiniPlayerProps } from '@/features/audio-player/ui/mini-player/types';
import { useIsMobile } from '@/shared/ui-kit/hooks/useIsMobile';

export const MiniPlayer = (props: MiniPlayerProps) => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <MiniPlayerMobile {...props} />
  ) : (
    <MiniPlayerDesktop {...props} />
  );
};
