import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';

import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M8.66667 18C9.58333 18 10.3333 17.2286 10.3333 16.2857V7.71429C10.3333 6.77143 9.58333 6 8.66667 6C7.75 6 7 6.77143 7 7.71429V16.2857C7 17.2286 7.75 18 8.66667 18ZM13.6667 7.71429V16.2857C13.6667 17.2286 14.4167 18 15.3333 18C16.25 18 17 17.2286 17 16.2857V7.71429C17 6.77143 16.25 6 15.3333 6C14.4167 6 13.6667 6.77143 13.6667 7.71429Z'
      fill='currentColor'
    />
  ),
});

const IconPause: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconPause);
