import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';

import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M16.7 6C16.3134 6 16 6.3134 16 6.7V10.9091L8.47885 6.80665C7.81248 6.44317 7 6.92548 7 7.68454V16.3155C7 17.0745 7.81248 17.5568 8.47885 17.1934L16 13.0909V17.3C16 17.6866 16.3134 18 16.7 18C17.0866 18 17.4 17.6866 17.4 17.3V6.7C17.4 6.3134 17.0866 6 16.7 6Z'
      fill='currentColor'
    />
  ),
});

const IconNext: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconNext);
