import { useUnit } from 'effector-react';
import { useRouter } from 'next/navigation';
import { signIn, useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';

import { usePlayerData } from '@/features/audio-player/hooks/use-player-data';
import { ControlsConnector } from '@/features/audio-player/ui/controls-connector';
import { MiniPlayerDefaultBuySlot } from '@/features/audio-player/ui/mini-player/mini-player-default-buy-slot';
import { TimeLineConnector } from '@/features/audio-player/ui/timeline-connector';
import { useFavorites } from '@/features/favorites';
import { DemuzoDrawerShare } from '@/shared/components/demuzo-drawer-share';
import { DemuzoModalShare } from '@/shared/components/demuzo-modal-share';
import { useIsMobile } from '@ui-kit/hooks/useIsMobile';
import { useShare } from '@ui-kit/hooks/useShare';

import { MiniPlayer } from './mini-player/mini-player';
import { usePlayList, usePlayerControls, usePlayerState } from '../hooks';
import { toggleFullscreenPlayer } from '../model';

export const MiniPlayerConnector = () => {
  const t = useTranslations();
  const onToggleFullscreenPlayer = useUnit(toggleFullscreenPlayer);
  const { currentTrack } = usePlayList();
  const { push } = useRouter();
  const { play, pause } = usePlayerControls();
  const { pending, playerState } = usePlayerState();
  const { data } = usePlayerData();
  const {
    handleCloseDrawerShare,
    isOpenDrawerShare,
    shareDetails,
    handleShare,
  } = useShare();
  const handleShareProductLink = () => {
    const currentShareDetails = {
      url: `${process.env.NEXT_PUBLIC_BASE_URL}/project/${data?.currentCampaignId}`,
      title: 'Demuzo',
    };

    handleShare(currentShareDetails);
  };
  const { favorites, handleToggle } = useFavorites();
  const isMobile = useIsMobile();
  const canBuy = data?.canBuy;
  const session = useSession();
  const onBuyClick = useCallback(
    async (id: string) => {
      const callbackUrl = `/project/purchase/${id}`;
      if (session.status === 'unauthenticated') {
        await signIn('keycloak', { callbackUrl });
        return;
      }

      push(callbackUrl);
    },
    [push, session.status],
  );

  if (!currentTrack?.name) {
    return;
  }

  return (
    <>
      <MiniPlayer
        data={{
          coverUrl: currentTrack?.coverUrl,
          snippetName: currentTrack.name,
          snippetAuthor: currentTrack.authors
            .map((item) => item.name)
            .join(' x '),
          id: currentTrack.id,
        }}
        isLoading={pending}
        onOpenPlayer={() => onToggleFullscreenPlayer(true)}
        playControl={{
          isPlaying: playerState === 'playing',
          onClick: () => (playerState === 'playing' ? pause() : play()),
        }}
        onBuy={() => {
          onBuyClick(data?.currentCampaignId || '');
        }}
        canBuy={canBuy}
        isLiked={favorites.includes(data.currentCampaignId || '')}
        onLike={() => handleToggle(data.currentCampaignId || '')}
        onShare={handleShareProductLink}
        buySlot={
          canBuy ? (
            <MiniPlayerDefaultBuySlot
              onClick={() => {
                onBuyClick(data?.currentCampaignId || '');
              }}
              text='Buy'
            />
          ) : null
        }
        playControls={
          <>
            <ControlsConnector />
            <TimeLineConnector />
          </>
        }
      />
      {isMobile ? (
        <DemuzoDrawerShare
          shareDetails={shareDetails}
          onClose={handleCloseDrawerShare}
          isOpen={isOpenDrawerShare}
        />
      ) : (
        <DemuzoModalShare
          shareDetails={shareDetails}
          onClose={handleCloseDrawerShare}
          isOpen={isOpenDrawerShare}
        />
      )}
    </>
  );
};
