import { useUnit } from 'effector-react';

import { FooterConnector } from '@/features/audio-player/ui/footer-connector';

import { ControlsConnector } from './controls-connector';
import { FullscreenPlayer } from './fullscreen-player/fullscreen-player';
import { TimeLineConnector } from './timeline-connector';
import { usePlayList } from '../hooks';
import { $fullscreenPlayer, toggleFullscreenPlayer } from '../model';

export const FullscreenPlayerConnector = () => {
  const { currentTrack } = usePlayList();
  const [onToggleFullscreenPlayer, fullscreenPlayer] = useUnit([
    toggleFullscreenPlayer,
    $fullscreenPlayer,
  ]);

  return (
    <FullscreenPlayer
      data={{
        albumCoverUrl: currentTrack?.coverUrl,
        snippetName: currentTrack?.name,
        snippetAuthors: currentTrack?.authors.map((item) => ({
          name: item.name,
          avatarUrl: item.avatarUrl,
        })),
        id: currentTrack?.id,
      }}
      isOpen={fullscreenPlayer}
      playControls={
        <>
          <TimeLineConnector />
          <ControlsConnector />
        </>
      }
      onClose={() => onToggleFullscreenPlayer(false)}
      footer={<FooterConnector />}
    />
  );
};
