import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';

import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M8.29998 6C8.68658 6 8.99998 6.3134 8.99998 6.7V10.9091L16.5211 6.80665C17.1875 6.44317 18 6.92548 18 7.68454V16.3155C18 17.0745 17.1875 17.5568 16.5211 17.1934L8.99998 13.0909V17.3C8.99998 17.6866 8.68658 18 8.29998 18C7.91338 18 7.59998 17.6866 7.59998 17.3V6.7C7.59998 6.3134 7.91338 6 8.29998 6Z'
      fill='currentColor'
    />
  ),
});

const IconPrev: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconPrev);
