import { useUnit } from 'effector-react';

import { $currentTrack, $playlist, setPlayList } from '../model';

export const usePlayList = () => {
  const [playlist, onSetPlayList, currentTrack] = useUnit([
    $playlist,
    setPlayList,
    $currentTrack,
  ]);

  return {
    playlist,
    currentTrack,
    setPlayList: onSetPlayList,
  };
};
