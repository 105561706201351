import { createIcon } from '@chakra-ui/react';
import { FC, memo } from 'react';

import { iconSizes } from './constants';
import { IconPropsWithSize } from './types';

const Ic = createIcon({
  viewBox: '0 0 24 24',
  path: (
    <path
      d='M8.00774 21.2455C6.67443 22.0232 5 21.0615 5 19.5179V5.48207C5 3.93849 6.67443 2.97675 8.00774 3.75452L20.0385 10.7724C21.3615 11.5442 21.3615 13.4558 20.0385 14.2276L8.00774 21.2455Z'
      fill='currentColor'
    />
  ),
});

const IconPlay: FC<IconPropsWithSize> = ({ size = 'lg', ...props }) => (
  <Ic
    {...iconSizes[size]}
    {...props}
  />
);

export default memo(IconPlay);
