import { combine, createEvent, createStore, restore, sample } from 'effector';

import { PlayedData, PlayerState, Snippet } from '../types';

export const setPlayList = createEvent<Snippet[]>();
export const $playlist = restore(setPlayList, []);

export const setCurrentTrack = createEvent<Snippet>();
export const $currentTrack = restore(setCurrentTrack, null);

export const setCurrentTrackIndex = createEvent<number>();
export const $currentTrackIndex = restore(setCurrentTrackIndex, 0);

export const setPlayerState = createEvent<PlayerState>();
export const $playerState = restore(setPlayerState, 'pause');

export const setTrackDurationSec = createEvent<number>();
export const $trackDurationSec = restore(setTrackDurationSec, 0);

export const setTrackCurrentTimeSec = createEvent<number>();
export const $trackCurrentTimeSec = restore(setTrackCurrentTimeSec, 0);

export const setPending = createEvent<boolean>();
export const $pending = restore(setPending, false);

export const setError = createEvent<boolean>();
export const $error = restore(setError, false);

export const nextTrack = createEvent();
export const previousTrack = createEvent();

export const toggleFullscreenPlayer = createEvent<boolean>();
export const $fullscreenPlayer = restore(toggleFullscreenPlayer, false);

export const setPlayerData = createEvent<Partial<PlayedData>>();
export const $playerData = createStore<PlayedData>({});
$playerData.on(setPlayerData, (state, payload) => ({ ...state, ...payload }));

$currentTrack.on(setPlayList, (_, payload) => payload.at(0) || null);
$currentTrackIndex.on(setPlayList, () => 0);
$playerState.on(setPlayList, () => 'pause');

sample({
  clock: $currentTrackIndex,
  source: $playlist,
  fn: (playlist, index) => {
    return playlist.at(index) || null;
  },
  target: $currentTrack,
});

export const $isNextTrack = sample({
  clock: combine($currentTrackIndex, $playlist),
  fn: ([index, playList]) => {
    return index + 1 < playList.length;
  },
});

export const $isPreviousTrack = $currentTrackIndex.map((i) => i - 1 >= 0);

sample({
  clock: nextTrack,
  filter: $isNextTrack,
  source: {
    trackIndex: $currentTrackIndex,
  },
  fn: ({ trackIndex }) => trackIndex + 1,
  target: $currentTrackIndex,
});

sample({
  clock: previousTrack,
  filter: $isPreviousTrack,
  source: { trackIndex: $currentTrackIndex },
  fn: ({ trackIndex }) => trackIndex - 1,
  target: $currentTrackIndex,
});
